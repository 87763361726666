import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import React, { lazy, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import axios from "axios";
import { themeChange } from 'theme-change'
import { checkAuth, getAccessToken, isAuthenticated  } from './app/auth';
import initializeApp from './app/init';
import Reports from './features/reports';

import { setnoOfNotifications } from './features/common/headerSlice';
import './App.css';

// Importing pages
const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const Register = lazy(() => import('./pages/Register'))
// const Documentation = lazy(() => import('./pages/Documentation'))


// Initializing different libraries
initializeApp()

function App() {
  const [notificationData, setNotificationData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false);

    // Verificar si verifiedLogin está establecido en true en localStorage
    const verifiedLogin = localStorage.getItem('verifiedLogin') === 'true';

    if (verifiedLogin) {
      const fetchData = async () => {
        try {
          const host = process.env.REACT_APP_BACKEND_URL;
          const response = await axios.get(host + '/ws/notification', {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getAccessToken()}`,
            },
          });

          setNotificationData(response.data.data);

          const totalElements = response.data.data.reduce((total, obj) => {
            return total + Object.keys(obj).length;
          }, 0);

          dispatch(setnoOfNotifications({ noOfNotifications: totalElements }))
          // console.log("Elementos", response.data.data, totalElements);

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      // Llamar a fetchData() al montar el componente
      fetchData();

      // Configurar intervalo para llamar a fetchData() cada 50 segundos
      const intervalId = setInterval(fetchData, 50000);

      // Limpiar el intervalo cuando el componente se desmonta o verifiedLogin se convierte en false
      return () => clearInterval(intervalId);
    }
  }, [dispatch]);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
          {/* <Route element={<Navigate to={isAuthenticated() ? <ForgotPassword /> : "/login"} replace />}/> */}

          {/* <Route path="/documentation" element={<Documentation />} /> */}

          {/* Place new routes over this */}
          {/* <Route path="/app/*" element={<Layout />} /> */}
          <Route path="*" element={<Navigate to={checkAuth() ? "/app/home" : "/login"} replace />}/>
          {/* <Route element={<Navigate to={checkAuth() ? "/register" : "/login"} replace />}/>
          <Route element={<Navigate to={isAuthenticated() ? "/reports" : "/login"} replace />}/> */}
          
          {/* Place new routes over this */}
          {checkAuth() ? (
              <Route path="/app/*" element={<Layout />} />
          ) : ( <Route path="/app/*" element={<Navigate to="/login" replace />} />)}

          <Route path="/app/reports/:id" element={<Reports />} />
          <Route path="/register" element={<Register />} />
        </Routes>
      </Router>
    </>
  )

}

export default App;
