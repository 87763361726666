import axios from "axios"
import { jwtDecode as jwt_decode } from 'jwt-decode';

export const checkAuth = () => {
  const access_token = localStorage.getItem('access_token'); // Cambia 'access_token' al nombre real de tu token

  if (!access_token) {
      // Si no hay un access_token, redirige a la página de inicio de sesión
      const PUBLIC_ROUTES = ["login", "forgot-password", "register", "documentation"];
      const isPublicPage = PUBLIC_ROUTES.some(r => window.location.href.includes(r));

      if (!isPublicPage) {
          window.location.href = '/login'; // Redirige a la página de inicio de sesión si no estás en una página pública
      }
  } else {
      // Configura el encabezado de autorización en Axios con el access_token
      axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

      axios.interceptors.request.use(function (config) {
          // Agrega aquí cualquier manipulación de solicitud adicional si es necesario
          return config;
      }, function (error) {
          return Promise.reject(error);
      });

      axios.interceptors.response.use(function (response) {
          // Agrega aquí cualquier manipulación de respuesta adicional si es necesario
          return response;
      }, function (error) {
          return Promise.reject(error);
      });
    //   const decodedToken = jwt_decode(access_token);
      return access_token;
  }
};
export const getAccessToken = () => {
   
  return localStorage.getItem('access_token');
};

export const isAuthenticated = () => {
    // Lógica para verificar si el usuario está autenticado
    // Por ejemplo, verifica si hay un token de acceso en el almacenamiento local
    const accessToken = localStorage.getItem('access_token');
    return !!accessToken; // Devuelve true si el usuario está autenticado, false en caso contrario
  };

export default checkAuth